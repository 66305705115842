/*
JetBrains Mono
* FiraCode
* Source Code Pro
* Inconsolata
* Iosevka
* Ubuntu Mono
* Github Monaspace
*/
const globalShotFontFunData = [
  {
    key: 'sarasa_gothic',
    default: 'SARASA GOTHIC',
    fontFace: 'Sarasa Fixed SC',
  },
  {
    key: 'lxgw_wenkai',
    default: 'LXGW WenKai',
    fontFace: 'LXGW WenKai Mono Lite',
  },
  {
    key: 'slidefu',
    default: 'Buddhism',
    fontFace: 'Slidefu',
  },
  {
    key: 'jiangxizhoukai',
    default: 'JiangXi Kai',
    fontFace: 'jiangxizhoukai',
  },
  {
    key: 'mplus2',
    default: 'M Plus 2',
    fontFace: 'M Plus 2',
  },
  {
    key: 'makinas4square',
    default: 'Makinas 4 Square',
    fontFace: 'Makinas 4 Square',
  },
  {
    key: 'xiaolaispell',
    default: 'XiaoLai Script Spell',
    fontFace: 'Mengshen-Handwritten',
  },
  {
    key: 'xiaolai',
    default: 'XiaoLai Script',
    fontFace: 'Xiaolai Mono SC',
  },
  {
    key: 'kinghwaoldsong',
    default: 'KingHwa Old Song',
    fontFace: 'KingHwa_OldSong',
  },
]
export default globalShotFontFunData;
