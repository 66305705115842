import React from 'react';

import { Radio, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";

export default function ModelThemes(shotConfig,handleShotConfig) {

    const { t } = useTranslation();
    const { Title } = Typography;

    
  const onChangeTheme = (e) => {
    let _shotConfig = shotConfig;
    _shotConfig.shotTheme = e.target.value;
    _shotConfig.shotBackground = '0';
    _shotConfig.shotTextColor = '0';
    _shotConfig.shotTextBackground = '0';
    handleShotConfig(_shotConfig)
  };
  const frameThemes = (<>
    <Title level={4}>{t('menu.theme.code.title', 'Theme Code')}</Title>
    <Radio.Group onChange={onChangeTheme} value={shotConfig.shotTheme}>
      <Space direction="vertical">
        <Radio key='theme1' value={'dark'}>{t('menu.theme.code.dark', 'Dark')}</Radio>
        <Radio key='theme2' value={'github-dark'}>{t('menu.theme.code.github_dark', 'Github Dark')}</Radio>
        <Radio key='theme3' value={'github-dark-dimmed'}>{t('menu.theme.code.github_dark_dimmed', 'Github Dark Dimmed')}</Radio>
        <Radio key='theme4' value={'github-light'}>{t('menu.theme.code.github_light', 'Github Light')}</Radio>
        <Radio key='theme5' value={'night-owl'}>{t('menu.theme.code.night_owl', 'Night Owl')}</Radio>
        <Radio key='theme6' value={'xcode'}>{t('menu.theme.code.xcode', 'XCode')}</Radio>
        <Radio key='theme7' value={'panda'}>{t('menu.theme.code.panda', 'Panda')}</Radio>
      </Space>
    </Radio.Group>
    <Title level={4}>{t('menu.theme.fun.title', 'Theme Fun')}</Title>
    <Radio.Group onChange={onChangeTheme} value={shotConfig.shotTheme}>
      <Space direction="vertical">
        <Radio key='theme101' value={'simple'}>{t('menu.theme.fun.simple', 'Simple')}</Radio>
        <Radio key='theme102' value={'chinese'}>{t('menu.theme.fun.chinese', 'Chinese')}</Radio>
        <Radio key='theme103' value={'chinese-handwriting'}>{t('menu.theme.fun.chinese_handwriting', 'Chinese Handwriting')}</Radio>
        <Radio key='theme104' value={'chinese-ancient'}>{t('menu.theme.fun.chinese_ancient', 'Chinese Ancient')}</Radio>
        <Radio key='theme105' value={'chinese-zen'}>{t('menu.theme.fun.chinese_zen', 'Chinese Zen')}</Radio>
        <Radio key='theme106' value={'barbie'}>{t('menu.theme.fun.barbie', 'Barbie')}</Radio>
        <Radio key='theme107' value={'japanese'}>{t('menu.theme.fun.japanese', 'Japanese')}</Radio>
        <Radio key='theme108' value={'japanese-makinas'}>{t('menu.theme.fun.japanese_makinas', 'Japanese Makinas')}</Radio>
        <Radio key='theme109' value={'chinese-peom'}>{t('menu.theme.fun.chinese_peom', 'Chinese Peom')}</Radio>
        <Radio key='theme110' value={'chinese-newspaper'}>{t('menu.theme.fun.chinese_newspaper', 'Chinese Newspaper')}</Radio>
      </Space>
    </Radio.Group>
    {/* <Button type="default" onClick={handleGetBingBackground} size="small">Bing</Button> */}
  </>);
    return (frameThemes);
}